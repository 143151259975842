import { Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Application, Brand, ThemeAndBrandService } from '@onyx/core';
import { CustomAction, ResponsiveService, UserProfileInfo } from '@onyx/shell';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  public title = 'Digital Twin Manager';
  public isMobile$: Observable<boolean>;
  public profileConfig: UserProfileInfo = {
    email: '',
    firstName: '',
    lastName: '',
    logoutAction: new CustomAction(() => this._authService.logout())
  };

  constructor(
    private _stackTheme: ThemeAndBrandService,
    responsiveService: ResponsiveService,
    public _authService: MsalService
  ) {
    this.isMobile$ = responsiveService.responsiveFlags$.pipe(
      map(flags => flags.mobile)
    );
    this._stackTheme.changeBrand(Brand.Kion);
    this._stackTheme.changeApplication(Application.Orchestrator);
  }
}
