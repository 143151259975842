import { Component } from '@angular/core';
import { environment } from '../../environments/environment';
import { DigitalTwinNodeTypeEnum } from '../models/digital-twin-node-type.enum';
import { DigitalTwinApiService } from '../services/digital-twin-api.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-home',
  template: `
    <ui-shell-content-header [sticky]="true">
      <p>redirectUri: {{ environment.redirectUri }}</p>
      <p>environment: {{ environment.cloudApiUri }}</p>
      <p>sample response: {{ nodes | async | json }}</p>
    </ui-shell-content-header>
  `,
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  public environment = environment;
  public nodes: Observable<object>;

  constructor(private _service: DigitalTwinApiService) {
    this.nodes = this._service.searchNode({
      page: 1,
      size: 10,
      nodeType: DigitalTwinNodeTypeEnum.Group,
      parentId: -1
    });
  }
}
