import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { GetNodesRequest } from '../models/get-nodes-request';

@Injectable({ providedIn: 'root' })
export class DigitalTwinApiService {
  private _uri: string;
  private _key: string;

  constructor(private _http: HttpClient) {
    this._key = environment.cloudApiKey;
    this._uri = environment.cloudApiUri;
  }

  public searchNode(request: GetNodesRequest): Observable<object> {
    return this._http.post<object>(`${this._uri}api/v3/store/search`, request, {
      headers: { 'api-key': this._key }
    });
  }
}
