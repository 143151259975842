import {
  ExtendedNavigationItem,
  NavigationConfig,
  NavigationItem,
  UrlAction
} from '@onyx/shell';

const navigationItems: NavigationItem[] = [
  {
    key: 'home',
    label: 'Home',
    customIcon: false,
    link: [''],
    iconName: 'home',
    exactMatch: true
  },
  {
    key: 'twinrequestoverview',
    label: 'Twin creation overview',
    customIcon: true,
    link: ['twinrequestoverview'],
    iconName: 'icon_deploy',
    exactMatch: true
  }
];

const extendedNavigationItems: ExtendedNavigationItem[] = [
  {
    key: 'zeroheight',
    label: 'ZeroHeight code walkthrough',
    action: new UrlAction(
      'https://kion.zeroheight.com/styleguide/s/77261/p/33b83b-stack-shell'
    )
  }
];

export const navigationStructure: NavigationConfig = {
  navigationItems,
  extendedNavigationItems
};
