import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { HomeComponent } from './home/home.component';
import { RequestOverviewComponent } from './requestoverview/requestoverview.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [MsalGuard],
    children: [
      { path: '', component: HomeComponent },
      { path: 'twinrequestoverview', component: RequestOverviewComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
