import { Component } from '@angular/core';

@Component({
  selector: 'app-sidepanel',
  template: `
    <ui-shell-sidepanel-content [title]="title" [(active)]="show">
      <h3>Content of the sidepanel</h3>
    </ui-shell-sidepanel-content>
    <div class="custom-layout full">
      <ui-shell-panel class="top">
        <button mat-flat-button (click)="show = !show">Toggle Sidepanel</button>
      </ui-shell-panel>
      <ui-shell-panel class="main">
        <h3>Content of the page</h3>
        <div>
          <p>Custom icon: <mat-icon svgIcon="icon_AGV"> </mat-icon></p>
        </div>
      </ui-shell-panel>
      <ui-shell-panel class="bottom">Bottom bar</ui-shell-panel>
    </div>
  `,
  styleUrl: './requestoverview.component.scss'
})
export class RequestOverviewComponent {
  public show = false;
  public title: string = 'My sidepanel title';
}
