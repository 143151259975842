<ui-shell>
  <ng-container content>
    <router-outlet></router-outlet>
  </ng-container>
  <div sidePanel>
    <ng-content select="[sidePanel]"></ng-content>
  </div>
  <ui-shell-global-settings>
    <ui-shell-global-settings-user-preferences>
      <ui-setting-category>
        <ui-setting-category-item>
          <ui-shell-global-settings-category-screen></ui-shell-global-settings-category-screen>
        </ui-setting-category-item>
      </ui-setting-category>
    </ui-shell-global-settings-user-preferences>
    <ui-shell-global-setting-panel
      key="component-placement"
      title="Component placement"
    >
      <ui-setting-category-item label="Show traffic light">
        <mat-slide-toggle data-testid="toggle"> </mat-slide-toggle>
      </ui-setting-category-item>
    </ui-shell-global-setting-panel>
  </ui-shell-global-settings>
  <ui-shell-profile [userInfo]="profileConfig" />

</ui-shell>
